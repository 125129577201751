import React, { useEffect, useState, useCallback } from 'react'
import { COOKIES, ROUTES } from '../utils/constants'
import { BrowserRouter, Route, Routes, Navigate, } from "react-router-dom";


// Redux
import { useSelector, useDispatch } from 'react-redux'

// ROUTES
import Login from '../pages/login/Login';
import GuestReservation from '../pages/GuestReservation';
import AuthorizedUserReservation from '../pages/user/AuthorizedUserReservation';
import SecurityMenu from '../pages/menus/SecurityMenu';
import UserSettings from '../pages/menus/UserSettings';
import CreateUserInternal from '../pages/user/CreateUserInternal';
import ServiceCarsTracking from '../pages/service/ServiceCarsTracking';
import NoPage from '../pages/NoPage';
import ReservationEdit from '../pages/visit/ReservationEdit';
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import ReservationTable from '../pages/visit/ReservationTable';
import ReservationApproval from '../pages/visit/ReservationApproval';
import ReportingMenu from '../pages/menus/ReportingMenu';
import CompanyCarsTracking from '../pages/company/CompanyCarsTracking';
import CardlessEmployeeTracking from '../pages/cardless-employee/CardlessEmployeeTracking';
import DailyVisitTracking from '../pages/visit/DailyVisitTracking';
import VisitingOperationsMenu from '../pages/menus/VisitingOperationsMenu';
import UsersTable from '../pages/user/UsersTable';
import UserEdit from '../pages/user/UserEdit';
import LoadReservation from '../pages/load/LoadReservation';
import VisitDailyWeeklyFirm from '../pages/visit/VisitDailyWeeklyFirm';
import LoadReservationTable from '../pages/load/LoadReservationTable';
import FreightHandlingMenu from '../pages/menus/FreightHandlingMenu';
import LoadReservationEdit from '../pages/load/LoadReservationEdit';
import CustomSnackbar from '../UI/CustomSnackbar';
import CreateFirmInternal from '../pages/company/CreateFirmInternal';
import UserMenu from '../pages/menus/UserMenu';
import StandardUserMenu from '../pages/menus/StandardUserMenu';
import StandardUserSettings from '../pages/menus/StandardUserSettings';
import { PERMISSIONS } from '../utils/constants';
import AuthCheckFunction from '../utils/authCheckFunction';
import ClarificationText from '../UI/ClarificationText';
import UserService from '../services/UserService';
import cookie from "react-cookies"
import { setUser } from '../redux/user';
import CardlessEmployeeAddOrEdit from '../pages/cardless-employee/CardlessEmployeeAddOrEdit';
import CompanyCarAddOrEdit from '../pages/company/CompanyCarAddOrEdit';
import ServiceCarAddOrEdit from '../pages/service/ServiceCarAddOrEdit';
import LoadReservationApproval from '../pages/load/LoadReservationApproval';
import LoadSaveInOut from '../pages/load/LoadSaveInOut';
import ForgotPassword from '../pages/login/ForgotPasswordPage';
import ContactUs from '../pages/ContactUs';
import FreightCalendar from '../pages/load/FreightCalendar';
import VisitCalendar from '../pages/visit/VisitCalendar';
import ViewAppointments from '../pages/visit/ViewAppointments';
import Register from '../pages/Register';
import UserApproval from '../pages/user/UserApproval';
import AppointmentBoard from '../pages/AppointmentBoard';
import AppointmentBoard2 from '../pages/AppointmentBoard-2';
import OperationSettings from '../pages/user/OperationSettings';
import AddOperationResponsible from '../pages/user/AddOperationResponsible';
import WorkingHoursConfig from '../pages/user/WorkingHoursManagement';
import GetCompanies from '../pages/company/GetCompanies';
import LanguageToggle from '../UI/LanguageToggle';
import EditCompany from '../pages/company/EditCompany';
import ShipmentCalendar from '../pages/load/ShipmentCalendar';

const AppRouter = () => {

    const dispatch = useDispatch()
    const userService = new UserService();

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isAuthChecked, setIsAuthChecked] = useState(false)
    const [sendToLogin, setSendToLogin] = useState(false)

    const user = useSelector((state) => state.user);
    useEffect(() => {
        loginWithAuthToken()
    }, [])

    const loginWithAuthToken = async () => {
        let token = cookie?.load(COOKIES.AUTHORIZATION)
        const slicedToken = token?.split(' ')[1]
        try {
            if (slicedToken) {
                const result = await userService.loginWithToken(slicedToken)
                if (result && result.data.status === 'Success') {
                    const user = result.data.data
                    dispatch(setUser(user))
                }
                else {
                    setSendToLogin(true)
                }

            }
            else {
                setSendToLogin(true)

            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsAuthChecked(true)
        }
    }

    const protectedRoutes = [
        { path: ROUTES.AUTH_USER_RESERVATION, element: AuthorizedUserReservation, permission: PERMISSIONS.AUTH_USER_RESERVATION },
        { path: ROUTES.USER_MENU, element: UserMenu, permission: PERMISSIONS.USER_MENU },
        { path: ROUTES.SECURITY_MENU, element: SecurityMenu, permission: PERMISSIONS.SECURITY_MENU },
        { path: ROUTES.USER_SETTINGS, element: UserSettings, permission: PERMISSIONS.USER_SETTINGS },
        { path: ROUTES.FREIGHT_HANDLING_MENU, element: FreightHandlingMenu, permission: PERMISSIONS.FREIGHT_HANDLING_MENU },
        { path: ROUTES.RESERVATION_TABLE, element: ReservationTable, permission: PERMISSIONS.RESERVATION_TABLE },
        { path: ROUTES.COMPANY_CARS_TRACKING, element: CompanyCarsTracking, permission: PERMISSIONS.COMPANY_CARS_TRACKING },
        { path: ROUTES.SERVICE_CARS_TRACKING, element: ServiceCarsTracking, permission: PERMISSIONS.SERVICE_CARS_TRACKING },
        { path: ROUTES.RESERVATION_APPROVAL, element: ReservationApproval, permission: PERMISSIONS.RESERVATION_APPROVAL },
        { path: ROUTES.RESERVATION_EDIT, element: ReservationEdit, permission: PERMISSIONS.RESERVATION_EDIT },
        { path: ROUTES.REPORTING_MENU, element: ReportingMenu, permission: PERMISSIONS.REPORTING_MENU },
        { path: ROUTES.CARDLESS_EMPLOYEE_TRACKING, element: CardlessEmployeeTracking, permission: PERMISSIONS.CARDLESS_EMPLOYEE_TRACKING },
        { path: ROUTES.DAILY_VISIT_TRACKING, element: DailyVisitTracking, permission: PERMISSIONS.DAILY_VISIT_TRACKING },
        { path: ROUTES.VISITING_OPERATIONS_MENU, element: VisitingOperationsMenu, permission: PERMISSIONS.VISITING_OPERATIONS_MENU },
        { path: ROUTES.USERS_TABLE, element: UsersTable, permission: PERMISSIONS.USERS_TABLE },
        { path: ROUTES.USER_EDIT, element: UserEdit, permission: PERMISSIONS.USER_EDIT },
        { path: ROUTES.VISIT_DAILY_WEEKLY_FIRM, element: VisitDailyWeeklyFirm, permission: PERMISSIONS.VISIT_DAILY_WEEKLY_FIRM },
        { path: ROUTES.LOAD_RESERVATION, element: LoadReservation, permission: PERMISSIONS.LOAD_RESERVATION },
        { path: ROUTES.LOAD_RESERVATION_TABLE, element: LoadReservationTable, permission: PERMISSIONS.LOAD_RESERVATION_TABLE },
        { path: ROUTES.LOAD_RESERVATION_EDIT, element: LoadReservationEdit, permission: PERMISSIONS.LOAD_RESERVATION_EDIT },
        { path: ROUTES.LOAD_RESERVATION_APPROVAL, element: LoadReservationApproval, permission: PERMISSIONS.LOAD_RESERVATION_APPROVAL },
        { path: ROUTES.CREATE_USER_INTERNAL, element: CreateUserInternal, permission: PERMISSIONS.CREATE_USER_INTERNAL },
        { path: ROUTES.CREATE_FIRM_INTERNAL, element: CreateFirmInternal, permission: PERMISSIONS.CREATE_FIRM_INTERNAL },
        { path: ROUTES.USER_MENU, element: UserMenu, permission: PERMISSIONS.USER_MENU },
        { path: ROUTES.STANDARD_USER_MENU, element: StandardUserMenu, permission: PERMISSIONS.STANDARD_USER_MENU },
        { path: ROUTES.STANDARD_USER_SETTINGS, element: StandardUserSettings, permission: PERMISSIONS.STANDARD_USER_SETTINGS },
        { path: ROUTES.SERVICE_CAR_ADD_OR_EDIT, element: ServiceCarAddOrEdit, permission: PERMISSIONS.SERVICE_CAR_ADD_OR_EDIT },
        { path: ROUTES.COMPANY_CAR_ADD_OR_EDIT, element: CompanyCarAddOrEdit, permission: PERMISSIONS.COMPANY_CAR_ADD_OR_EDIT },
        { path: ROUTES.CARDLESS_EMPLOYEE_ADD_OR_EDIT, element: CardlessEmployeeAddOrEdit, permission: PERMISSIONS.CARDLESS_EMPLOYEE_ADD_OR_EDIT },
        { path: ROUTES.CLARIFICATION_TEXT, element: ClarificationText, permission: PERMISSIONS.GUEST },
        { path: ROUTES.LOAD_SAVE_IN_OUT, element: LoadSaveInOut, permission: PERMISSIONS.LOAD_SAVE_IN_OUT },
        { path: ROUTES.FREIGHT_CALENDAR, element: FreightCalendar, permission: PERMISSIONS.FREIGHT_CALENDAR },
        { path: ROUTES.VISIT_CALENDAR, element: VisitCalendar, permission: PERMISSIONS.VISIT_CALENDAR },
        { path: ROUTES.VIEW_APPOINTMENTS, element: ViewAppointments, permission: PERMISSIONS.VIEW_APPOINTMENTS },
        { path: ROUTES.USER_APPROVAL, element: UserApproval, permission: PERMISSIONS.USER_APPROVAL },
        { path: ROUTES.APPOINTMENT_BOARD, element: AppointmentBoard, permission: PERMISSIONS.APPOINTMENT_BOARD },
        { path: ROUTES.APPOINTMENT_BOARD_2, element: AppointmentBoard2, permission: PERMISSIONS.APPOINTMENT_BOARD_2 },
        { path: ROUTES.OPERATION_SETTINGS, element: OperationSettings, permission: PERMISSIONS.OPERATION_SETTINGS },
        { path: ROUTES.ADD_OPERATION_RESPONSIBLE, element: AddOperationResponsible, permission: PERMISSIONS.OPERATION_SETTINGS },
        { path: ROUTES.WORKING_HOURS_CONFIG, element: WorkingHoursConfig, permission: PERMISSIONS.WORKING_HOURS_CONFIG },
        { path: ROUTES.COMPANIES, element: GetCompanies, permission: PERMISSIONS.COMPANIES },
        { path: ROUTES.EDIT_COMPANY, element: EditCompany, permission: PERMISSIONS.EDIT_COMPANY },
        { path: ROUTES.SHIPMENT_CALENDAR, element: ShipmentCalendar, permission: PERMISSIONS.SHIPMENT_CALENDAR }
    ]

    const getProtectedRoutes = useCallback(() => {
        return protectedRoutes.map((route, i) => {
            return <Route key={i} exact path={route.path} element={<ProtectedRoute element={AuthCheckFunction(route.element, route.permission, user)} />} />
        })
    }, [user])



    if (isAuthChecked) return <BrowserRouter>
        <CustomSnackbar />
        <Routes>
            <Route exact path={ROUTES.INDEX} element={<PublicRoute sendToLogin={sendToLogin} element={Login} />} />
            <Route exact path={ROUTES.GUEST} element={<PublicRoute sendToLogin={sendToLogin} element={GuestReservation} />} />
            <Route exact path={ROUTES.CONTACT_US} element={<PublicRoute element={ContactUs} />} />
            <Route exact path={ROUTES.REGISTER} element={<PublicRoute element={Register} />} />
            <Route exact path={ROUTES.NO_PAGE} element={<PublicRoute sendToLogin={sendToLogin} element={NoPage} />} />
            <Route exact path={ROUTES.RESET_PASSWORD} element={<PublicRoute element={ForgotPassword} />} />

            {getProtectedRoutes()}

            <Route
                path="*"
                element={<Navigate replace to={ROUTES.INDEX} />}
            />
        </Routes>
    </BrowserRouter>
    else return null



}

export default AppRouter;
