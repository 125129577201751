import { APPOINTMENT_TYPES } from '../utils/constants'
import { ENDPOINTS } from '../utils/constants'
import axios from '../utils/interceptor'

export default class AppointmentService {

    constructor() {
        this.endpoint = ENDPOINTS.APPOINTMENTS
    }

    getAppointments = async (params) => {

        try {
            // Request
            let result = await axios.get(this.endpoint.BASE, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    createAppointment = async (appointment) => {

        let url = this.endpoint.BASE

        if (appointment.appointmentType === APPOINTMENT_TYPES.OPERATION)
            url = this.endpoint.FREIGHT // api/v1/appointments/freight for freight appointments

        else if (appointment.appointmentType === APPOINTMENT_TYPES.VISIT)
            url = this.endpoint.VISIT   // api/v1/appointments/visit for visit appointments

        try {
            // Request
            let result = await axios.post(url, appointment, { urlsToClear: [this.endpoint.BASE, this.endpoint.AVAILABLE_SLOTS] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    createGuestAppointment = async (appointment) => {
        let url = this.endpoint.BASE + '/guest-visit'

        try {
            // Request
            let result = await axios.post(url, appointment, { urlsToClear: this.endpoint.BASE })

            // //Check Error
            // if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    updateAppointment = async (appointmentId, body) => {

        let url = `${this.endpoint.BASE}/${appointmentId}`

        try {
            // Request
            let result = await axios.patch(url, body, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    getAvailableSlots = async (params) => {

        let url = `${this.endpoint.FREIGHT}/available-slots`

        try {
            // Request
            let result = await axios.get(url, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    getSlots = async (params) => {

        let url = `${this.endpoint.FREIGHT}/all-slots`

        try {
            // Request
            let result = await axios.get(url, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    getFixedShipmentAppointments = async (params) => {

        let url = `${this.endpoint.BASE}/shipment-calendar`

        try {
            // Request
            let result = await axios.get(url, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

}