import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import {
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { GridCloseIcon } from "@mui/x-data-grid";
import MainCard from '../../UI/MainCard';
import NavigationPanel from '../../UI/NavigationPanel';
import AppointmentService from '../../services/AppointmentService';

const styles = {
    tableContainer: {
        margin: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },
    cell: {
        border: '1px solid #ccc',
        height: '80px',
        width: '210px',
        boxSizing: 'border-box',
        padding: '10px',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': { backgroundColor: 'rgba(0,0,0,0.05)' }
    },
    appointmentContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    descriptionText: {
        fontWeight: 'bold',
        color: '#d32f2f'
    },
    companyBadge: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#fff',
        padding: '2px 5px',
        borderRadius: '3px',
        marginTop: '5px'
    },
    modal: {
        position: 'absolute',
        width: '400px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    modalInput: {
        marginBottom: '16px',
        width: '100%'
    }
};

const ShipmentCalendar = () => {
    const { t } = useTranslation();
    const appointmentService = new AppointmentService();

    const [appointments, setAppointments] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [editedData, setEditedData] = useState(null);
    const [panels,] = useState([t('adminPanel'), t('freightOperations'), t('shipmentCalendar')]);

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        fetchSlots();
        fetchAppointments();
    }, []);

    const fetchAppointments = async () => {
        const response = await appointmentService.getFixedShipmentAppointments();
        setAppointments(response.data);
    };

    const fetchSlots = async () => {
        const response = await appointmentService.getSlots();
        setSlots(response.data);
    };

    const handleOpen = (appointment) => {
        setSelectedAppointment(appointment);
        setEditedData({ ...appointment });
        setOpen(true);
    };

    const handleUpdate = async () => {
        try {
            await appointmentService.updateAppointment(editedData);
            setAppointments(prev => prev.map(a =>
                a.appointmentDay === editedData.appointmentDay &&
                    a.slotNumber === editedData.slotNumber ? editedData : a
            ));
            setOpen(false);
        } catch (error) {
            console.error('Update failed:', error);
        }
    };

    const getCompanyStyle = (companyId) => ({
        backgroundColor: companyId === 51 ? '#27ae60' : '#2980b9'
    });

    return (
        <MainCard isSingleCard={true}>
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <TableContainer component={Paper} sx={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                            {days.map(day => (
                                <TableCell key={day} align="center" sx={{ fontWeight: 'bold' }}>
                                    {t(day.toLowerCase())}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {slots.map(slot => (
                            <TableRow key={slot.SLOT_NUMBER}>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {slot.START} - {slot.END}
                                </TableCell>
                                {days.map(day => {
                                    const appointment = appointments.find(a =>
                                        a.appointmentDay === day && a.slotNumber === slot.SLOT_NUMBER
                                    );
                                    return (
                                        <TableCell
                                            key={`${day}-${slot.SLOT_NUMBER}`}
                                            sx={styles.cell}
                                            onClick={() => appointment && handleOpen(appointment)}
                                        >
                                            {appointment && (
                                                <div style={styles.appointmentContent}>
                                                    <Typography variant="body2" sx={{ ...styles.companyBadge, ...getCompanyStyle(appointment.companyId) }}>
                                                        {appointment.description}
                                                    </Typography>
                                                    {appointment.companyName}
                                                </div>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div style={styles.modal}>
                    <IconButton onClick={() => setOpen(false)} sx={{ float: 'right' }}>
                        <GridCloseIcon />
                    </IconButton>

                    {editedData && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Edit Appointment
                            </Typography>

                            <TextField
                                label="Description"
                                value={editedData.description}
                                onChange={(e) => setEditedData({ ...editedData, description: e.target.value })}
                                sx={styles.modalInput}
                                fullWidth
                            />

                            <TextField
                                label="Company"
                                value={editedData.companyName}
                                sx={styles.modalInput}
                                disabled
                                fullWidth
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpdate}
                                fullWidth
                            >
                                Update Appointment
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        </MainCard>
    );
};

export default ShipmentCalendar;
