import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, FormControlLabel, Switch } from '@mui/material';
import MainCard from '../../UI/MainCard';
import NavigationPanel from '../../UI/NavigationPanel';
import { DEFAULT_THEME } from '../../utils/useThemes';
import { useTranslation } from 'react-i18next';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useDispatch } from 'react-redux';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';
import ConfigurationService from '../../services/ConfigurationService'
import { RESPONSE_STATUSES } from '../../utils/constants';

const WorkingHoursConfig = () => {
    const styles = {
        container: {
            padding: '1rem',
            marginBottom: '1rem',
            marginLeft: '1rem',
        },
        fieldLabel: {
            marginBottom: '2rem',
        },
        formControl: {
            width: '100%',
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        breakItem: {
            alignItems: 'baseline',
            marginBottom: '1rem',
        },
        addBreakButton: {
            color: 'green',
            cursor: 'pointer',
            alignSelf: 'center',
        },
        deleteButton: {
            color: '#E45B5B',
            cursor: 'pointer',
            alignSelf: 'center',
        },
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        }
    }
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const configurationService = new ConfigurationService()

    const [config, setConfig] = useState({})
    const [configFirst, setConfigFirst] = useState({})

    const [disabled, setDisabled] = useState(true)
    const [panels,] = useState([t('adminPanel'), t('freightOperations'), t('workingHoursManagement')])

    useEffect(() => {
        getConfiguration()
    }, [])

    useEffect(() => {
        setDisabled(JSON.stringify(config) === JSON.stringify(configFirst))
    }, [config])

    const getConfiguration = async () => {
        try {
            const result = await configurationService.getConfiguration()

            setConfig(result.data)
            setConfigFirst(result.data)

        } catch (e) {
            console.log(e)
        }
    }
    const handleSave = async () => {
        const response = await configurationService.updateConfiguration(config)
        if (response && response.status === RESPONSE_STATUSES.success) {
            setDisabled(true)
        }
    };

    return (

        <MainCard isSingleCard={true}>
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container spacing={2} sx={styles.container}>
                <Grid item xs={12}>
                    <h2>{t('workingHoursManagement')}</h2>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            label={t('startTime')}
                            type="time"
                            value={config?.workingHours?.start || ""}
                            onChange={(e) =>
                                setConfig({
                                    ...config,
                                    workingHours: { ...config?.workingHours, start: e.target.value },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={t('endTime')}
                            type="time"
                            value={config?.workingHours?.end || ""}
                            onChange={(e) =>
                                setConfig({
                                    ...config,
                                    workingHours: { ...config?.workingHours, end: e.target.value },
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={t('slotDuration')}
                            type="number"
                            value={config?.slotDuration || ""}
                            sx={{ width: '80%' }}
                            onChange={(e) =>
                                setConfig({ ...config, slotDuration: parseInt(e.target.value) })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sx={styles.fieldLabel}>
                        <h3>{t('breaks')} </h3>
                        {config?.breaks?.map((breakTime, index) => (
                            <Grid container key={index} xs={8} alignItems="center" sx={styles.breakItem}>
                                <Grid item xs={2}>
                                    <TextField
                                        label={`${t('breakStart')} ${index + 1}`}
                                        type="time"
                                        value={breakTime.start}
                                        sx={{ width: '80%' }}
                                        onChange={(e) => {
                                            const newBreaks = [...config.breaks];
                                            newBreaks[index].start = e.target.value;
                                            setConfig({ ...config, breaks: newBreaks });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        label={`${t('breakEnd')} ${index + 1}`}
                                        type="time"
                                        value={breakTime.end}
                                        onChange={(e) => {
                                            const newBreaks = [...config?.breaks];
                                            newBreaks[index].end = e.target.value;
                                            setConfig({ ...config, breaks: newBreaks });
                                        }}
                                    />
                                </Grid>

                                <Grid item sx={styles.addBreakButton}>
                                    {index === 0 && (
                                        <MoreTimeIcon
                                            aria-label="add"
                                            title={t('addBreak')}
                                            style={{ width: '2rem', height: '2rem' }}
                                            onClick={() => {
                                                setConfig({
                                                    ...config,
                                                    breaks: [...config?.breaks, { start: "", end: "" }],
                                                });
                                            }}
                                        >
                                        </MoreTimeIcon >)}
                                </Grid>

                                {/* Add button to remove break */}
                                <Grid item sx={styles.deleteButton}>
                                    {index !== 0 && (
                                        <AutoDeleteIcon
                                            aria-label="delete"
                                            title={t('delete')}
                                            style={{ width: '2rem', height: '2rem' }}
                                            onClick={() => {
                                                const newBreaks = [...config?.breaks];
                                                newBreaks.splice(index, 1);
                                                setConfig({ ...config, breaks: newBreaks });
                                            }}
                                        >
                                        </AutoDeleteIcon >)}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} sx={styles.fieldLabel}>
                        <h3>{t('extraHours')} </h3>
                        <Grid item xs={12} sx={{ display: 'flex' }} >
                            <Grid item xs={2}>
                                <TextField
                                    label={t('startTime')}
                                    type="time"
                                    value={config?.extraHours?.start || ""}
                                    disabled={!config?.overtime}
                                    onChange={(e) =>
                                        setConfig({
                                            ...config,
                                            extraHours: { ...config?.extraHours, start: e.target.value },
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label={t('endTime')}
                                    type="time"
                                    value={config?.extraHours?.end || ""}
                                    onChange={(e) =>
                                        setConfig({
                                            ...config,
                                            extraHours: { ...config?.extraHours, end: e.target.value },
                                        })
                                    }
                                    disabled={!config.overtime}
                                />
                            </Grid>

                            <Grid item container justifyContent="space-between" sx={styles.inputField}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={config?.overtime}
                                            onChange={(e) => {
                                                setConfig({
                                                    ...config,
                                                    overtime: e.target.checked,
                                                })
                                            }}
                                            name="extraHoursSwitch"
                                            color="primary"
                                        />
                                    }
                                    label={config.overtime ? t('Açık') : t('Kapalı')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={styles.fieldLabel}>
                        <h3>{t('weekendBooking')} </h3>
                        <Grid item container justifyContent="space-between" sx={styles.inputField}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config?.weekendBooking}
                                        onChange={(e) => {
                                            setConfig({
                                                ...config,
                                                weekendBooking: e.target.checked,
                                            })
                                        }}
                                        name="weekendBooking"
                                        color="primary"
                                    />
                                }
                                label={config.weekendBooking ? t('Açık') : t('Kapalı')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            onClick={() => dispatch(setMessageAndButtonText({
                                'title': t('saveConfiguration'),
                                'text': t('saveConfigurationText'),
                                'noButtonText': t("commonCancelButton"),
                                noButtonAction: () => {
                                    dispatch(hidePopup())
                                },
                                'yesButtonText': t("commonSaveButton"),
                                yesButtonAction: () => {
                                    handleSave()
                                    dispatch(hidePopup())
                                }
                            }))}>
                            {t('saveConfiguration')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default WorkingHoursConfig;
