//MUI
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RuleIcon from '@mui/icons-material/Rule';

//Library
import MainCard from '../../UI/MainCard';
import { ROLES, ROUTES } from '../../utils/constants';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';

//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useSelector } from 'react-redux';
import Card from '../../UI/Card';
import { CalendarMonthOutlined } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

const FreightHandlingMenu = () => {

    const { t } = useTranslation();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const [panels,] = useState([t('adminPanel'), t('freightOperations')])
    const user = useSelector((state) => state.user);

    const styles = {
        subTitle: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: '1rem'
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }
    }

    const fields = [
        {
            text: t('bookANewFreightAppointment'),
            navigate: ROUTES.LOAD_RESERVATION,
            children: <LocalShippingOutlinedIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.AUTHORIZED_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SUPER_ADMIN]
        },
        {
            text: t('freightAppointments'),
            navigate: ROUTES.LOAD_RESERVATION_TABLE,
            children: <FormatListBulletedIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.AUTHORIZED_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SUPER_ADMIN]
        },
        {
            text: t('commonDecisionRes'),
            navigate: ROUTES.LOAD_RESERVATION_APPROVAL,
            children: <RuleIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE]
        },
        {
            text: t('freightCalendar'),
            navigate: ROUTES.FREIGHT_CALENDAR,
            children: <CalendarMonthOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE]
        },
        {
            text: t('shipmentCalendar'),
            navigate: ROUTES.SHIPMENT_CALENDAR,
            children: <CalendarMonthOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE]
        },
        {
            text: t('operationSettings'),
            navigate: ROUTES.OPERATION_SETTINGS,
            children: <SettingsIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER]
        },
        {
            text: t('workingHoursManagement'),
            navigate: ROUTES.WORKING_HOURS_CONFIG,
            children: <ManageHistoryIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER]
        }
    ]

    const filteredFields = fields.filter(f => f.roles.includes(user?.type))

    return (

        <MainCard isSingleCard={true}>

            <Grid container item xs={12} sx={{ ...styles.subTitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            <Grid container>
                {
                    filteredFields.map((field) => {
                        return (
                            <Card field={field} styles={styles} />
                        )
                    })
                }
            </Grid>

        </MainCard>


    );
}

export default FreightHandlingMenu;