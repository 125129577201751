//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';

//MUI
import AssessmentIcon from '@mui/icons-material/Assessment';
import HandshakeIconOutlined from '@mui/icons-material/HandshakeOutlined';
import BadgeIconOutlined from '@mui/icons-material/BadgeOutlined';
import AirportShuttleIconOutlined from '@mui/icons-material/AirportShuttleOutlined';
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import MainCard from '../../UI/MainCard';
import { ROLES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME } from '../../utils/useThemes';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import { CalendarMonthOutlined } from '@mui/icons-material';
import NavigationPanel from '../../UI/NavigationPanel';
import Card from '../../UI/Card';
import { Grid } from '@mui/material';

const UserMenu = () => {

    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const [panels,] = useState([t('adminPanel'), t('mainMenu')])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: "17px",
        }
    }

    const fields = [
        {
            text: t('freightOperations'),
            navigate: ROUTES.FREIGHT_HANDLING_MENU,
            children: <LocalShippingOutlinedIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.AUTHORIZED_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SUPER_ADMIN]
        },
        {
            text: t('visitOperations'),
            navigate: ROUTES.VISITING_OPERATIONS_MENU,
            children: <HandshakeIconOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER]
        },
        {
            text: t('reporting'),
            navigate: ROUTES.REPORTING_MENU,
            children: <AssessmentIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.AUTHORIZED_USER, ROLES.SUPER_ADMIN]
        },
        {
            text: t('cardlessEmployeeOperations'),
            navigate: ROUTES.CARDLESS_EMPLOYEE_TRACKING,
            children: <BadgeIconOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.SUPER_ADMIN]
        },
        {
            text: t('companyCarsOperations'),
            navigate: ROUTES.COMPANY_CARS_TRACKING,
            children: <DirectionsCarIconOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.SUPER_ADMIN]
        },
        {
            text: t('serviceCarsOperations'),
            navigate: ROUTES.SERVICE_CARS_TRACKING,
            children: <AirportShuttleIconOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.SUPER_ADMIN]
        },
        {
            text: t('freightCalendar'),
            navigate: ROUTES.FREIGHT_CALENDAR,
            children: <CalendarMonthOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.OPERATION_RESPONSIBLE, ROLES.AUTHORIZED_USER, ROLES.SUPER_ADMIN]
        },
        {
            text: t('shipmentCalendar'),
            navigate: ROUTES.SHIPMENT_CALENDAR,
            children: <CalendarMonthOutlined sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.OPERATION_RESPONSIBLE, ROLES.AUTHORIZED_USER, ROLES.SUPER_ADMIN]
        },
        {
            text: t('visitCalendar'),
            navigate: ROUTES.VISIT_CALENDAR,
            children: <PermContactCalendarIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.SUPER_ADMIN]
        },
        {
            text: t('userSettings'),
            navigate: ROUTES.USER_SETTINGS,
            children: <PeopleIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.AUTHORIZED_USER, ROLES.SUPER_ADMIN]
        },
        {
            text: t('appointmentBoard'),
            navigate: ROUTES.APPOINTMENT_BOARD,
            children: <CalendarMonthIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD]
        },
        {
            text: t('appointmentBoard-2'),
            navigate: ROUTES.APPOINTMENT_BOARD_2,
            children: <CalendarMonthIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.SECURITY_GUARD]
        },
        {
            text: t('companyList'),
            navigate: ROUTES.COMPANIES,
            children: <FormatListBulleted sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER]
        }
    ]

    const filteredFields = fields.filter(f => f.roles.includes(user?.type))

    return (
        <MainCard isSingleCard={true}>

            <Grid container item xs={12} sx={{ ...styles.subtitle }} alignItems='center'>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container>
                {
                    filteredFields.map((field) =>
                        <Card field={field} styles={styles} />
                    )
                }
            </Grid>
        </MainCard >

    );
}

export default UserMenu;